import React, { ButtonHTMLAttributes } from 'react';

type Buttons = {
  label: string;
  className?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extras?: ButtonHTMLAttributes<HTMLButtonElement>;
  onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
};

type ModalProps = {
  id: number;
  hidden: boolean;
  title: string;
  content: string;
  buttons: Buttons[];
  onClose: Function;
};

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  return (
    <div
      className="cb-modal"
      id={`modal${props.id}`}
      aria-hidden={props.hidden ? 'true' : 'false'}
      data-cb-apricot="modal"
      style={{ display: props.hidden ? 'none' : 'block' }}
    >
      <div className="cb-modal-overlay" tabIndex={props.hidden ? -1 : 1} data-cb-modal-close>
        <div className="cb-modal-container" role="dialog" aria-modal="true" aria-labelledby={`modalID${props.id}title`}>
          <div className="cb-modal-header">
            <h2 className="cb-modal-title" id={`modal${props.id}title`}>
              {props.title}
            </h2>

            <button
              type="button"
              className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close"
              aria-describedby={`modal${props.id}title`}
              onClick={(e) => props.onClose(e)}
              data-cb-modal-close
            >
              <span className="cb-glyph cb-x-mark" aria-hidden="true"></span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="cb-modal-content">
            <p>{props.content}</p>
          </div>
          <div className="cb-modal-footer">
            <div className="cb-btn-row">
              {props.buttons.map((btn, i) => (
                <button
                  key={i}
                  type="button"
                  className={['cb-btn', btn.className].join(' ')}
                  disabled={btn.disabled}
                  aria-label={`${btn.label} the dialog window`}
                  onClick={btn.onClick}
                  {...btn.extras}
                >
                  {btn.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
