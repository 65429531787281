import React, { FunctionComponent } from 'react';
import { GlobalHeader } from '@cb/global-widgets-react';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: FunctionComponent<LayoutProps> = (props: LayoutProps) => (
  <>
    <GlobalHeader
      appId={426}
      programType="k-12"
      siteCode="itestassurance"
      useLoginWidget={true}
      useSearchWidget={false}
      useGlobalNavigation={true}
      useGlyphLogo={false}
      identityConfig={{
        redirectUrl: `${window.location.protocol}//${window.location.host}/login`,
      }}
    ></GlobalHeader>
    {props.children}
  </>
);

export default Layout;
