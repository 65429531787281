import { actionIds, BaseAction } from '../actions';

export enum ProtocolCheckStatus {
  Unknown,
  Validating,
  Valid,
  Invalid,
}

export type ProtocolCheckState = {
  status: ProtocolCheckStatus;
};

export const protocolCheckReducer = (state: ProtocolCheckState | undefined, action: BaseAction): ProtocolCheckState => {
  if (typeof state === 'undefined') {
    return { status: ProtocolCheckStatus.Unknown };
  }

  switch (action.type) {
    case actionIds.PROTOCOL_CHECK_STARTED:
      return { status: ProtocolCheckStatus.Validating };
    case actionIds.PROTOCOL_CHECK_SUCCEEDED:
      return { status: ProtocolCheckStatus.Valid };
    case actionIds.PROTOCOL_CHECK_FAILED:
      return { status: ProtocolCheckStatus.Invalid };
    default:
      return state;
  }
};
