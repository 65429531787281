import React from 'react';
import { Spacer, Button } from '@cb/apricot-react';

import Band from './Band';

const OptOut: React.FC = () => {
  return (
    <>
      <Band title="Thank You for Your Interest" />
      <div className="container fluid">
        <div className="row ">
          <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
            <Spacer />
            <p>We may reach out to see if you want to participate in future digital testing pilots.</p>
            <p>
            If you change your mind, just reload this site and click through to adjust your responses before April 19, 2021.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OptOut;
