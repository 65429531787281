import React from 'react';
import { Spacer, Button } from '@cb/apricot-react';

import Band from './Band';

const CompletePage: React.FC = () => {
  return (
    <>
      <Band title="Your Registration Is Confirmed" />
      <div className="container fluid">
        <div className="row ">
          <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
            <Spacer />
            <p>
            Thanks for signing up for the pilot. We’ll send you an email a few days before the test with instructions for you to download the digital testing app.
            </p>

            <p>
            If you want to change your registration, just reload this site and click through to adjust your responses before April 19, 2021.
            </p>

            <p>
              If you have any questions about the pilot, please contact us at digitaltesting@collegeboard.org.
            </p>

            <p>Good luck on test day!</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletePage;
