import { put, takeLatest } from 'redux-saga/effects';
import { actionIds, validateStartedAction, validateFailedAction, validateSucceededAction, Raott } from '../actions';
import { validate } from '../api/invites.api';

function* validateWithRaott(action: any) {
  try {
    yield put(validateStartedAction());
    const response = yield validate(action.payload as Raott);
    if (response.headers['content-type'] === 'text/html') {
      yield put(validateFailedAction(null));
    } else {
      if (!response.data.data) {
        throw new Error('invalid token');
      }
      yield put(
        validateSucceededAction({
          response: response.data.data,
          raott: action.payload,
          protocol: 'cb-exam-player',
        }),
      );
    }
  } catch (e) {
    yield put(validateFailedAction(e));
  }
}
export function* watchValidate() {
  yield takeLatest(actionIds.VALIDATE, validateWithRaott);
}
