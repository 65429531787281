import { actionIds, BaseAction } from '../actions';
import LevityAdapter from '../util/levity-adapter';
import { AuthIdentity, ParsedJwt } from '../util/levity-adapter-types';
import { LevityAuthSession } from '../util/levity-types';

export * from '../util/levity-adapter';
export * from '../util/levity-adapter-types';
export * from '../util/levity-types';

export type AuthState = {
  session?: LevityAuthSession;
  authIdentity: AuthIdentity;
  userEmail?: string;
  userName?: string;
  authenticationUrl?: () => string;
  authenticationToken?: string;
  authorizationToken?: string;
  parsedToken?: ParsedJwt;
};

const { iam } = window.cb.core;

const stateWithCurrentCbAuth = (prevState: AuthState): AuthState => {
  const adapter = new LevityAdapter(iam, process.env.REACT_APP_BASE_URL);
  return {
    ...prevState,
    session: adapter.session,
    userEmail: adapter.userEmail,
    userName: adapter.userName,
    authenticationToken: adapter.authenticationToken,
    authorizationToken: adapter.token,
    authIdentity: adapter.authIdentity,
    authenticationUrl: adapter.authenticationUrl,
    parsedToken: adapter.parsed,
  };
};

export const authReducer = (state: AuthState | undefined, action: BaseAction): AuthState => {
  if (typeof state === 'undefined') {
    return stateWithCurrentCbAuth({ authIdentity: AuthIdentity.Anonymous });
  }

  switch (action.type) {
    case actionIds.LOGIN_STARTED:
    case actionIds.LOGIN_SUCCEEDED:
    case actionIds.LOGIN_FAILED:
    case actionIds.LOGIN_REFRESH:
    case actionIds.LOGOUT_STARTED:
    case actionIds.LOGOUT_SUCCEEDED:
      return stateWithCurrentCbAuth(state);
    default:
      return state;
  }
};
