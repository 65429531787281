import { actionIds, BaseAction } from '../actions';

export enum ValidateStatus {
  Unknown,
  Validating,
  Valid,
  Invalid,
}

export type ValidateState = {
  status: ValidateStatus;
};

const stateWithCurrent = (
  prevState: ValidateState,
  possibleStatus: ValidateStatus,
  validateResult?: boolean,
): ValidateState => {
  const status =
    validateResult === null || validateResult === undefined
      ? possibleStatus
      : validateResult
      ? ValidateStatus.Valid
      : ValidateStatus.Invalid;

  const val = {
    ...prevState,
    status,
  };
  return val;
};

export const validateReducer = (state: ValidateState | undefined, action: BaseAction): ValidateState => {
  if (typeof state === 'undefined') {
    return { status: ValidateStatus.Unknown };
  }

  switch (action.type) {
    case actionIds.VALIDATE_STARTED:
      return stateWithCurrent(state, ValidateStatus.Validating);
    case actionIds.VALIDATE_SUCCEEDED:
      return stateWithCurrent(state, ValidateStatus.Validating, action.payload);
    case actionIds.VALIDATE_FAILED:
      return stateWithCurrent(state, ValidateStatus.Invalid);
    default:
      return state;
  }
};
