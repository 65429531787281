import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useHistory, RouteComponentProps } from 'react-router-dom';
import { State, UserStatus } from '../reducers';

import Home from './Home';
import DenyPage from './Deny';
import LoadingPage from './Loading';
import CompletePage from './Complete';
import WelcomePage from './Welcome';
import TermsAndConditionsPage from './TermsAndConditions';
import OptOutPage from './OptOut';

type RoutesProps = {};

const redirectPath = (userStatus: UserStatus) => {
  switch (userStatus) {
    case UserStatus.Denied:
      return '/deny';
    case UserStatus.Unauthenticated:
      return '/login';
    case UserStatus.OptedOut:
      return '/optout';
    case UserStatus.OptedIn:
      return '/confirmed';
    default:
      return '/';
  }
};

const StateSetRoute = ({
  allowedStates,
  component: Component,
  userStatus,
  ...rest
}: {
  userStatus: UserStatus;
  allowedStates: UserStatus[];
  component: React.FunctionComponent<RouteComponentProps>;
  path: string;
  exact?: boolean;
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (allowedStates.includes(userStatus)) {
        return <Component {...props} />;
      }
      
      switch (userStatus) {
        case UserStatus.Unknown:
        case UserStatus.Initializing:
        case UserStatus.SavingAccept:
          return <LoadingPage />;
        case UserStatus.Unauthenticated:
          return <Redirect to="/login" />;
        case UserStatus.Denied:
          return <Redirect to="/deny" />;
      }

      return <Redirect to="/" />;
    }}
  />
);

const Routes: FunctionComponent<RoutesProps> = (props) => {
  const history = useHistory();
  const userStatus = useSelector((state: State) => state.fsm.userStatus);

  useEffect(() => {
    switch (userStatus) {
      case UserStatus.Unauthenticated:
        history.replace('/login');
        break;
      case UserStatus.Denied:
        history.replace('/deny');
        break;
      case UserStatus.OptedOut:
        history.replace('/optout');
        break;
      case UserStatus.OptedIn:
        history.replace('/confirmed');
        break;
    }
  }, [userStatus, history]);

  return (
    <div>
      <Switch>
        <StateSetRoute
          path="/login"
          userStatus={userStatus}
          component={Home}
          allowedStates={[UserStatus.Unknown, UserStatus.Unauthenticated]}
        />
        <StateSetRoute
          path="/"
          exact={true}
          userStatus={userStatus}
          component={WelcomePage}
          allowedStates={[UserStatus.LoggedIn, UserStatus.Registered, UserStatus.Unregistered]}
        />
        <StateSetRoute
          path="/loading"
          userStatus={userStatus}
          component={LoadingPage}
          allowedStates={[UserStatus.Unknown, UserStatus.Initializing, UserStatus.SavingAccept]}
        />
        <StateSetRoute
          path="/optout"
          userStatus={userStatus}
          component={OptOutPage}
          allowedStates={[UserStatus.OptedOut]}
        />
        <StateSetRoute
          path="/termsandconditions"
          userStatus={userStatus}
          component={TermsAndConditionsPage}
          allowedStates={[UserStatus.Registered, UserStatus.Unregistered]}
        />
        {/* <StateSetRoute
          path="/download"
          userStatus={userStatus}
          component={DownloadPage}
          allowedStates={[UserStatus.Registered]}
        /> */}
        <StateSetRoute
          path="/confirmed"
          userStatus={userStatus}
          component={CompletePage}
          allowedStates={[UserStatus.OptedIn]}
        />
        <StateSetRoute path="/deny" userStatus={userStatus} component={DenyPage} allowedStates={[UserStatus.Denied]} />
        {/* <Route path="/ep/:id" component={ExamPlayerLauncher} /> */}
        <Redirect to={redirectPath(userStatus)} />
      </Switch>
    </div>
  );
};

export default Routes;
