import React, { useEffect } from 'react';
import { Spacer } from '@cb/apricot-react';
import { HeroIdentity } from '@cb/global-widgets-react';

const Home: React.FC = () => {
  useEffect(() => {
    document.title = 'Student Registration Login | College Board';
  });
  return (
    <div id="login-1">
      <HeroIdentity
        appId={426}
        headerText="Student Registration Portal"
        bodyText={`Welcome to the Student Registration Portal`}
        imageUrl="/hero.png"
        program="k-12"
        redirectUrl={`${window.location.protocol}//${window.location.host}/login`}
      />
      <Spacer />
    </div>
  );
};

export default Home;
