import { actionIds, BaseAction } from '../actions';

export type InviteData = {
  data: {
    eventId: number;
    accepted?: boolean;
    raott: string;
  };
  downloadPeriod: Period;
  enrollPeriod: Period;
  examWindow: Period;
  siteOpen: boolean;
};

export type Period = {
  start: Date;
  end: Date;
  isOpen: boolean;
};

export enum InviteStatus {
  Unknown,
  Retrieving,
  Ready,
  Updating,
  Updated,
  GettingDownload,
  Downloading,
  Errored,
}

export type InviteState = {
  status: InviteStatus;
  item: null | InviteData;
};

const stateWithCurrentInvite = (prevState: InviteState, status: InviteStatus, initData?: InviteData): InviteState => {
  const val = {
    ...prevState,
    status: status,
  };
  if (initData) {
    val.item = { ...val.item, ...initData };
  }
  return val;
};

const hasAccepted = (prevState: InviteState, status: InviteStatus, accepted: boolean): InviteState => {
  return {
    ...prevState,
    status: status,
    item: {
      ...prevState.item,
      data: {
        ...prevState.item.data,
        accepted,
      },
    },
  };
};

export const inviteReducer = (state: InviteState | undefined, action: BaseAction): InviteState => {
  if (typeof state === 'undefined') {
    return { status: InviteStatus.Unknown, item: null };
  }

  switch (action.type) {
    case actionIds.REFRESH_INVITE_STARTED:
      return stateWithCurrentInvite(state, InviteStatus.Retrieving);
    case actionIds.REFRESH_INVITE_SUCCESS:
      return stateWithCurrentInvite(state, InviteStatus.Ready, action.payload);
    case actionIds.UPDATE_INVITE_STARTED:
      return stateWithCurrentInvite(state, InviteStatus.Updating);
    case actionIds.UPDATE_INVITE_SUCCESS:
      return hasAccepted(state, InviteStatus.Updated, action.payload);
    // case actionIds.DOWNLOAD_PLAYER_STARTED:
    //   return stateWithCurrentInvite(state, InviteStatus.GettingDownload);
    // case actionIds.DOWNLOAD_PLAYER_SUCCESS:
    //   return stateWithCurrentInvite(state, InviteStatus.Downloading);
    case actionIds.REFRESH_INVITE_ERROR:
    case actionIds.UPDATE_INVITE_ERROR:
    // case actionIds.DOWNLOAD_PLAYER_ERROR:
      return stateWithCurrentInvite(state, InviteStatus.Errored);
    default:
      return state;
  }
};
