import { AuthIdentity, ParsedJwt } from './levity-adapter-types';
import { LevityAuthSession } from './levity-types';

export default class LevityAdapter {
  token: string;
  parsed: ParsedJwt;
  session: LevityAuthSession;
  authIdentity: AuthIdentity;
  isLoggedIn: boolean;
  userEmail: string;
  userName: string;
  authenticationToken: string;
  baseUrl: string;
  isRaott: boolean;

  constructor(iam: any, baseUrl: string) {
    this.baseUrl = baseUrl;
    this.token = iam.getAuthorizationToken();

    this.parsed = {};
    if (this.token) {
      try {
        this.parsed = JSON.parse(atob(this.token.split('.')[1]));
      } catch (e) {
        //ignore it
      }
    }
    this.session = iam.getAuthSession();
    this.isLoggedIn = this.session && this.session.isLoggedIn;
    this.isRaott = this.isLoggedIn && this.session.loginTokenType === 'CBLoginWeak';

    const isAllowed = this.isLoggedIn ? this.session.basicProfile.isStudent : false;
    if (this.isRaott) {
      this.authIdentity = AuthIdentity.Raott;
    } else if (this.isLoggedIn && isAllowed) {
      this.authIdentity = AuthIdentity.AuthorizedTarget;
    } else if (this.isLoggedIn && !isAllowed) {
      this.authIdentity = AuthIdentity.MiscUser;
    } else {
      this.authIdentity = AuthIdentity.Anonymous;
    }

    this.userEmail = this.isLoggedIn ? this.session.basicProfile.userName : '';
    this.userName = this.isLoggedIn ? (this.userEmail ? this.userEmail.slice(0, this.userEmail.indexOf('@')) : '') : '';
    this.authenticationToken = iam.getAuthenticationToken();
  }

  authenticationUrl = () => {
    const dUrl = '?DURL=' + window.location.protocol + '//' + window.location.host + '/';
    const subRoute = this.isLoggedIn ? '/logout' : '';
    return this.baseUrl + subRoute + dUrl;
  };
}
