import { put, takeLatest } from 'redux-saga/effects';
import {
  refreshInviteStartedAction,
  refreshInviteSuccessAction,
  refreshInviteErrorAction,
  updateInviteStartedAction,
  updateInviteSuccessAction,
  updateInviteErrorAction,
  // downloadPlayerStartedAction,
  // downloadPlayerSuccessAction,
  // downloadPlayerErrorAction,
  actionIds,
} from '../actions';
import { init, update, download } from '../api/invites.api';

const win = window as any;

function* requestInviteInfo(_action) {
  try {
    yield put(refreshInviteStartedAction());
    const response = yield init();
    if (response.headers && response.headers['content-type'] === 'text/html') {
      yield put(refreshInviteErrorAction(null));
    } else {
      yield put(refreshInviteSuccessAction(response.data));
    }
  } catch (e) {
    yield put(refreshInviteErrorAction(e));
  }
}

function* updateInviteInfo(action) {
  try {
    yield put(updateInviteStartedAction());
    const response = yield update(action.payload);
    if (response.headers && response.headers['content-type'] === 'text/html') {
      yield put(updateInviteErrorAction(null));
    } else {
      yield put(updateInviteSuccessAction(response.data?.accepted));
    }
  } catch (e) {
    yield put(updateInviteErrorAction(e));
  }
}

// function* requestDownload(action) {
//   try {
//     yield put(downloadPlayerStartedAction());
//     const response = yield download(action.payload);
//     if (response.headers['content-type'] === 'text/html') {
//       yield put(downloadPlayerErrorAction(null));
//     } else {
//       win.location = response.data;
//       yield put(downloadPlayerSuccessAction(response.data));
//     }
//   } catch (e) {
//     yield put(downloadPlayerErrorAction(e));
//   }
// }

export function* watchRefreshInvite() {
  yield takeLatest(actionIds.REFRESH_INVITE, requestInviteInfo);
}

export function* watchUpdateInvite() {
  yield takeLatest(actionIds.UPDATE_INVITE, updateInviteInfo);
}

// export function* watchDownloadPlayer() {
//   yield takeLatest(actionIds.DOWNLOAD_PLAYER, requestDownload);
// }
