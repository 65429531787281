import React from 'react';
import { Spacer } from '@cb/apricot-react';
import { useSelector } from 'react-redux';
import { State } from '../reducers';
import Band from './Band';

const DenyPage: React.FC = () => {
  const examEnd = useSelector((state: State) => state.invite.item?.examWindow?.end);
  const pastExam = examEnd ? new Date() > examEnd : false;
  const title = pastExam ? 'The Pilot Test Is Over' : 'This Testing Pilot Is Closed';
  const body = () => {
    if (pastExam) {
      return (
        <>
          <p>Our spring digital pilots are complete. You may be invited to participate in future digital testing pilots.</p>
        </>
      );
    }
    return (
      <>
        <p>We’re not accepting any more students for our spring 2021 digital testing pilots</p>
        <p>You may be invited to participate in future digital testing pilots.</p>
        <p>Thank you for your interest!</p>
      </>
    );
  };
  return (
    <>
      <Band title={title} />
      <Spacer />
      <div className="container fluid">
        <div className="row ">
          <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">{body()}</div>
        </div>
      </div>
    </>
  );
};

export default DenyPage;
