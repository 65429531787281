import { actionIds, BaseAction } from '../actions';
import { InviteData } from './invite.reducer';
import { AuthIdentity } from '../util/levity-adapter-types';
import LevityAdapter from '../util/levity-adapter';

export enum UserStatus {
  Unknown, //0
  Unauthenticated, //1
  LoggedIn, //2
  Initializing, //3
  Denied, //4
  Unregistered, //5
  Registered, //6
  SavingAccept, //7
  OptedIn, //8
  OptedOut, //9
  Downloading, //10
}

export enum RegistrationStatus {
  Unknown,
  Initializing,
  Unregistered,
  Registered,
}

export enum RaottStatus {
  Unknown, //0
  Validating, //1
  Invalid, //2
  Valid, //3
  Failure, //4
}

const { iam } = window.cb.core;

export class SiteStatus {
  inviteData: InviteData;
  isOpen: boolean;
  isEnrollable: boolean;
  isDownloadable: boolean;
  isExamable: boolean;

  constructor(inviteData: InviteData) {
    this.inviteData = inviteData;
    this.isOpen = inviteData?.siteOpen;
    this.isEnrollable = inviteData?.enrollPeriod?.isOpen;
    this.isExamable = inviteData?.examWindow?.isOpen;
    this.isEnrollable = inviteData?.enrollPeriod?.isOpen;
    this.isExamable = inviteData?.examWindow?.isOpen;
  }
}

export type FsmState = {
  userStatus: UserStatus;
  raottStatus: RaottStatus;
  siteStatus: null | SiteStatus;
};

const basicAuthState = (state: FsmState): FsmState => {
  const adapter = new LevityAdapter(iam, process.env.REACT_APP_BASE_URL);
  if (adapter.authIdentity === AuthIdentity.AuthorizedTarget) {
    return { ...state, userStatus: UserStatus.LoggedIn };
  } else if (adapter.authIdentity === AuthIdentity.MiscUser) {
    return { ...state, userStatus: UserStatus.Denied };
  } else {
    return { ...state, userStatus: UserStatus.Unauthenticated };
  }
};

export const fsmReducer = (state: FsmState | undefined, action: BaseAction): FsmState => {
  if (typeof state === 'undefined') {
    const adapter = new LevityAdapter(iam, process.env.REACT_APP_BASE_URL);
    if (adapter.authIdentity === AuthIdentity.AuthorizedTarget) {
      return { siteStatus: null, raottStatus: RaottStatus.Unknown, userStatus: UserStatus.LoggedIn };
    } else if (adapter.authIdentity === AuthIdentity.MiscUser) {
      return { siteStatus: null, raottStatus: RaottStatus.Unknown, userStatus: UserStatus.Denied };
    } else {
      return { siteStatus: null, raottStatus: RaottStatus.Unknown, userStatus: UserStatus.Unauthenticated };
    }
  }

  switch (action.type) {
    case actionIds.VALIDATE:
      return { ...state, raottStatus: RaottStatus.Unknown };
    case actionIds.VALIDATE_STARTED:
      return { ...state, raottStatus: RaottStatus.Validating };
    case actionIds.VALIDATE_SUCCEEDED:
      return { ...state, raottStatus: action.payload ? RaottStatus.Valid : RaottStatus.Invalid };
    case actionIds.VALIDATE_FAILED:
      return { ...state, raottStatus: RaottStatus.Failure };
    case actionIds.REFRESH_INVITE_STARTED:
      return { ...state, userStatus: UserStatus.Initializing };
    case actionIds.REFRESH_INVITE_SUCCESS:
      let newStatus = UserStatus.Denied;
      if (action.payload?.data?.eventId) {
        if (action.payload?.data?.accepted) {
          newStatus = UserStatus.Registered;
        } else {
          newStatus = action.payload?.enrollPeriod?.isOpen ? UserStatus.Unregistered : UserStatus.Denied;
        }
      }
      if (action.payload?.siteOpen === false) {
        newStatus = UserStatus.Denied;
      }
      return { ...state, userStatus: newStatus, siteStatus: new SiteStatus(action.payload) };
    case actionIds.UPDATE_INVITE_STARTED:
      return { ...state, userStatus: UserStatus.SavingAccept };
    case actionIds.UPDATE_INVITE_SUCCESS:
      return { ...state, userStatus: action.payload ? UserStatus.OptedIn : UserStatus.OptedOut };
    case actionIds.UPDATE_INVITE_ERROR:
      return { ...state, userStatus: UserStatus.Unknown };
    // case actionIds.DOWNLOAD_PLAYER_STARTED:
    //   return { ...state, userStatus: UserStatus.Downloading };
    // case actionIds.DOWNLOAD_PLAYER_SUCCESS:
    //   return { ...state, userStatus: UserStatus.Registered };
    case actionIds.REFRESH_INVITE_ERROR:
      return { ...state, userStatus: UserStatus.Unknown };
    case actionIds.LOGIN_STARTED:
      return { ...state, userStatus: UserStatus.Initializing };
    case actionIds.LOGIN_SUCCEEDED:
      return basicAuthState(state);
    case actionIds.LOGIN_FAILED:
      return basicAuthState(state);
    case actionIds.LOGIN_REFRESH:
      return basicAuthState(state);
    case actionIds.LOGOUT_STARTED:
      return { ...state, userStatus: UserStatus.Initializing };
    case actionIds.LOGOUT_SUCCEEDED:
      return { ...state, userStatus: UserStatus.Unauthenticated };
    case actionIds.SET_EVENTS:
      return basicAuthState(state);
    // case actionIds.DOWNLOAD_PLAYER_ERROR:
    //   return basicAuthState(state);
    default:
      return state;
  }
};
