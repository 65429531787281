import React, { CSSProperties, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spacer, Button, SpacerH, PrimaryButton } from '@cb/apricot-react';
import Band from './Band';
import { updateInviteAction } from '../actions';
import './TermsAndConditions.css';

const TermsAndConditions: React.FC = () => {
  const dispatch = useDispatch();
  const [canAccept, setCanAccept] = useState(false);

  return (
    <>
      <Band title="Terms and Conditions" />
      <div className="container fluid">
        <div className="row ">
          <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 col-2xl-6 offset-2xl-3 col-3xl-4 offset-3xl-4">
            <Spacer></Spacer>
            <p>To join the pilot, please read and accept these terms and conditions.</p>
            <Spacer></Spacer>
            {/* Card */}
            <div className="cb-card cb-card-link">
              <div aria-labelledby="c12" aria-describedby="c11 c13 c14" style={{ padding: '24px' }}>
                <div className="cb-card-content">
                  <div
                    id="c13"
                    className="cb-card-desc"
                    data-testid="1"
                    style={{ height: '380px', overflow: 'scroll', overflowX: 'hidden' }}
                    onScroll={(event) => {
                      const node = event.currentTarget;
                      if (node.scrollTop + node.offsetHeight >= node.scrollHeight - node.offsetHeight) {
                        setCanAccept(true);
                      }
                    }}
                  >

                    {/* START - Terms and Conditions */}

                    <h3 style={{ textAlign: 'center' }}>PILOT EXAM TERMS AND CONDITIONS</h3>
                    <Spacer />
                    <p>
                    These Terms and Conditions (“<b><i>Terms and Conditions</i></b>” or “<b><i>Agreement</i></b>”) are a legal contract between you and College Board (“<b><i>College Board</i></b>” or “<b><i>we</i></b>”). They set forth important rules and policies you must follow related to taking the Pilot Exam (“<b><i>Pilot Exam</i></b>”). Please read these carefully. If you register for the Pilot Exam on behalf of another (for example, if you are a parent or legal guardian of the test taker), these Terms and Conditions govern both you and the test taker (collectively, “<b><i>you</i></b>”).
                    </p>

                    <p>
                    Background. The purpose of the Pilot Exam is to provide information to College Board relating to its internal test development process. The Pilot Exam will be provided digitally. The Pilot Exam will allow College Board, including its Advanced Placement® (“<b><i>AP</i></b>”) program, to test certain questions as well as the digital and remote format of the assessment.  You will not receive scores or feedback on your responses nor can the Pilot Exam be used by you for credit or other purposes.  You understand that this exam is a pilot and College Board is under no obligation to include the format or new questions in a generally available offering.
                    </p>

                    <p>
                      <b>
                      All disputes between you and College Board will be resolved through binding arbitration in accordance with Section 6 of this Agreement. You understand that by agreeing to arbitration, you are waiving your right to resolve disputes in a court of law by a judge or jury.
                      </b>
                    </p>
                    <p>
                      <b>CONTENTS:</b>
                    </p>
                    <p>
                      <em>
                        Section 1. Taking the Exam
                        <br />
                        Section 2. Prohibited Behaviors
                        <br />
                        Section 3. Privacy
                        <br />
                        Section 4. Miscellaneous
                        <br />
                        Section 5. Intellectual Property Rights
                        <br />
                        Section 6. ARBITRATION OF DISPUTES AND CLASS ACTION WAIVER
                        <br />
                        Section 7.  Governing Law, Venue and Waiver of Jury Trial
                        <br />
                        Section 8. LIMITATIONS OF LIABILITY
                        <br />
                        Section 9. DISCLAIMER OF WARRANTIES
                        <br />
                        Section 10. Severability
                        <br />
                        Section 11. Restricted Registrations
                        <br />
                        Section 12. Accessibility of These Terms and Conditions
                        <br />
                      </em>
                    </p>
                    <p>
                      <b>Section 1. Taking the Exam</b>
                    </p>
                    <br />

                    <p>
                    The Pilot Exam will be administered in a digital format which will be taken at-home or in-school on an authorized, internet-connected device.  In-school testing means the Pilot Exam administered or proctored by a school on College Board’s behalf, either on the school’s premises, or outside of the school’s premises in a school-sanctioned facility. You must follow all instructions and requirements provided in connection with the Pilot Exam.  Failure to follow the Instructions may result in you not being able to successfully take the Pilot Exam.
                    </p>
                    <br />

                    <ol type="a">

                      <li>

                        <p>
                          <b>Required Items</b>
                          <ol className={'terms-and-conditions-list'} type="i">
                          <br/>

                            <li>A testing device with sufficient charge to enable you to complete the exam.</li>
                            <li>If you have been approved for testing accommodations, your College Board Student Accommodations Letter to verify your approval.</li>

                          </ol>
                        </p>
                        <br/>
                      </li>

                      <li>

                        <p>
                          <b>Prohibited Items</b>
                          <ol className={'terms-and-conditions-list'} type="i">
                          <br/>
                            <li>Electronic equipment including phones, smartwatches, fitness trackers, wearable technology, recording devices, listening devices, or any other type of electronic or communication device. The only exceptions to the foregoing restrictions are laptops or other devices specifically permitted to be used for digital testing.</li>
                            <li>Books, compasses, protractors, mechanical pencils, pencils that are not No. 2, correction fluid, dictionaries, highlighters, notes, or colored pencils.</li>
                            <li>Scratch paper: Students testing in school are prohibited from bringing scratch paper to the Pilot Exam, but scratch paper will be provided. Students testing at home are permitted to use their own scratch paper.</li>
                            <li>Watches that beep, make a noise, or have an alarm.</li>
                            <li>Computers or calculators that are not approved.</li>
                            <li>Reference guides, keyboard maps, or other typing instructions.</li>
                            <li>Ear plugs.</li>
                            <li>Clothing or shoes with subject-related information.</li>
                            <li>Food or drink.</li>
                            <li>Clipboards.</li>

                          </ol>
                          <p>NOTE: Some exceptions to the above may apply if a test taker has received a College Board approved accommodation.</p>
                        </p>
                        <br/>
                      </li>

                      <li>

                        <p>
                          <b>Testing Requirements  </b>
                          <ol className={'terms-and-conditions-list'} type="i">
                          <br/>
                            <li>Pilot Exam App.  Prior to taking the Pilot Exam, you must download the Pilot Exam application (“<b><i>Pilot Exam App</i></b>”) and complete the Exam Setup. You cannot take the Pilot Exam unless these steps have been successfully completed.</li>
                            <li>Internet &amp; Operating System. You need an internet connection to download the Pilot Exam and to submit your answers.  You must be running Windows 8 or later (PC users), Mac OS 10.11 or later (Mac users), or Chrome OS 83 or later (Chromebook users).  You may need to update your operating system.</li>
                            <li>Device Needed. You can use your desktop, laptop, or school-managed Chromebook. You cannot take the Pilot Exam on a tablet or on a mobile phone. If you’re on a PC, you need to have 250 MB of available disk space for one exam, plus an additional 100 MB for each subsequent exam. If you’re on a Mac, you need to have 400 MB of available disk space for one exam, plus an additional 100 MB for each subsequent exam. If you’re on a school-managed Chromebook, you need to have 100 MB of available disk space for one exam, plus an additional 100 MB for each subsequent exam.</li>
                            <li>Answer Submission. You will enter your answers in the Pilot Exam App.  Your answers are submitted when they are received by College Board.  If your testing device is connected to the internet when the exam ends, your answers will be automatically sent to College Board.  If your device is NOT connected to the internet when the test ends, you will need to connect to the internet and follow directions in the Pilot Exam App.  Answers must be received by College Board no later than 11:59 p.m. PT one day after the day on which you took the exam.</li>
                           
                          </ol>
                        </p>
                        <br/>
                      </li>

                    </ol>
                    <br />

                    <p>
                      <b>Section 2. Prohibited Behaviors</b>
                    </p>
                    <br />
                    <ol className={'terms-and-conditions-list'} type="a">
                      <li>
                      You <b>may not engage</b> in the prohibited behaviors:
                        <ol className={'terms-and-conditions-list'} type="i">
                          <li>
                          Attempting to cheat or otherwise obtain an unfair advantage on the exam.
                          </li>
                          <li>
                          Obtaining, or attempting to obtain, improper access to the exam, or a part of the exam, or information about the exam.
                          </li>
                          <li>
                          Accessing or attempting to access a phone of any kind or electronic device during testing or during breaks.
                          </li>
                          <li>
                          Improperly accessing or attempting to improperly access any electronic device during testing, except as permitted by the exam instructions.                        
                          </li>
                          <li>
                          Reproducing or attempting to reproduce any portion of any exam in any form (e.g., by taking photos or making photocopies).
                          </li>
                          <li>
                          Sharing exam content during or after the exam through any means, including, but not limited to, email, text messages, phone calls, photographs, social media, or the internet.
                          </li>
                          <li>
                          Having subject-related information on your clothing, shoes, or body.                            
                          </li>
                          <li>
                          Using testing accommodations not approved by College Board.
                          </li>
                          <li>
                          Copying the work of another student or of published or unpublished sources.
                          </li>
                          <li>
                          <u>Submitting any work that is not entirely your own.</u>. This includes submitting any text, content, graphics, pictures, or other content obtained from another source, including the internet, books, or class materials, for any part of the exam.                   
                          </li>
                          <li>
                          Attempting to give or get assistance, or otherwise communicate, through any means, with another person about the exam, during the exam administration, or after the exam administration. Anyone giving or accepting assistance will be investigated.</li>
                          <li>
                          Attempting to take or taking the exam for someone else.
                          </li>
                          <li>
                          Attempting to have or having someone else take the exam on your behalf.
                          </li>
                          <li>
                          Engaging in confrontational, threatening, or unruly behavior, conduct or communication toward or concerning any  test taker, test administrator or proctor, or employee of College Board or its vendors, including Educational Testing Service (“<b><i>ETS</i></b>”).
                          </li>
                          <li>
                          Failing to follow any of the test administration rules set forth in these Terms and Conditions, the Instructions, or in other registration materials, or directions given by the testing staff.
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <br />

                    <p>
                    <b>Section 3. Privacy</b>
                    </p>
                    <br />
                    <ol className={'terms-and-conditions-list'} type="a">
                      <li>
                      <b>Privacy Policies.</b> College Board recognizes the importance of protecting your privacy. Our privacy policies located at <a style={{ color: "blue" } as CSSProperties} href="https://about.collegeboard.org/privacy-center">collegeboard.org/privacy-center</a> (“<b><i>Privacy Policies</i></b>”) are part of these Terms and Conditions. You consent to College Board’s collection, use, and disclosure of your personally identifiable information as described in the Privacy Policies and in these Terms and Conditions. College Board may update its Privacy Policies from time to time and they are subject to change up to one week prior to your test date. You are required to review the Privacy Policies located at <a style={{ color: "blue" } as CSSProperties} href="https://about.collegeboard.org/privacy-center">collegeboard.org/privacy-center</a> prior to taking the Pilot Exam.
                      </li>
                      <li>

                      <p>
                          <b>Digital Testing</b>.
                          <ol className={'terms-and-conditions-list'} type="i">
                          <br/>
                            <li>Device Data:  When you download and use the Pilot Exam App, College Board will receive certain information about your device, including device type, operating system type and version, applications and processes running on your device, screen size and resolution, , available memory, and other information to ensure the device is compatible. </li>
                            <li>Activity Data:  We also monitor the actions you take in the Pilot Exam App, including your responses, where you click, how long you spend on each page, and how you navigate through the Pilot Exam App. Screenshots will be taken of your testing device.</li>
                            <li>Device Data and Activity Data (collectively, “<b><i>Pilot Test Data</i></b>”) may be used for College Board’s test security purposes as well as to develop and improve College Board products and services.  Pilot Test Data may be disclosed to trusted vendors, but only in their provision of services to College Board.  Pilot Test Data is not sold or licensed to third parties including without limitation for their marketing purposes.  You will also be asked to type specific sentences in the Pilot Exam App.  Those typed sentences are not used for biometric identification.</li>

                          </ol>
                        </p>
                        <br/>

                      </li>
                    
                    </ol>
                    <br />

                    <p>
                    <b>Section 4. Miscellaneous</b>
                    </p>
                    <br />
                    <ol className={'terms-and-conditions-list'} type="a">
                      <li>
                      In the event of a test security related concern, public health threat, natural disaster, terrorist act, civil unrest, epidemic, or other unexpected events or circumstances, College Board may cancel testing for all or a specific group of test takers. In addition, College Board may cancel at any time due to issues related to COVID-19 in College Board’s sole discretion. If this occurs, College Board will notify you in advance, if feasible.
                      </li>
                      <li>
                      To ensure the integrity of the Pilot Exam, for security reasons, or for other reasons in our sole discretion, College Board reserves the right to bar any individual or group of individuals from registering for and/or taking any College Board assessment.
                      </li>
                      <li>
                      College Board reserves the right to contact any appropriate individuals or agencies—including your high school—if we believe you may be in imminent danger of harming yourself or others, based on any information you provide to College Board, including in your responses to Pilot Exam questions. We might also provide the relevant essay or other content, along with your personal information, to those we contact.
                      </li>
                      <li>
                      College Board takes steps to ensure that registration records are properly handled and processed.
                      </li>
                      <li>
                      Each College Board contractor is a third-party beneficiary and is entitled to the rights and benefits under this Agreement and may enforce the provisions of this Agreement as if it were a party to this Agreement. 
                      </li>
                      <li>
                      College Board is not responsible for providing an internet connection or for internet service interruptions or errors outside of College Board’s control, such as data transmission errors on the public internet. 
                      </li>
                      <li>
                      College Board is not responsible for your failure to follow directions and instructions relating to the taking the Pilot Exam.
                      </li>
                      <li>
                      College Board will not be responsible for personal property, including devices brought on test day, that becomes lost, stolen, or damaged.
                      </li>
                    </ol>
                    <br />
                    <p>
                    <b>Section 5. Intellectual Property Rights</b>
                    </p>
                    <br />
                    <ol className={'terms-and-conditions-list'} type="a">
                      <li>
                      All College Board tests, test-related documents and materials, and test preparation materials (“<b><i>Test Content</i></b>”) are copyrighted works owned by College Board and protected by the laws of the United States and other countries.
                      </li>
                      <li>
                      All software, web pages, algorithms, processes, and technologies, including the Pilot Exam App, through which you access and take the Pilot Exam, your answers are scored, and the test is secured and proctored, but excluding your device, your internet service provider (ISP) and the public internet, (collectively, the “<b><i>Test Platform</i></b>”) belong to College Board and its licensors.  
                      </li>
                      <li>
                      You shall not screenshot or attempt to make any image, copy, or download Test Content or the Test Platform. You shall not attempt to decompile, reverse engineer, or dissemble the Test Platform.
                      </li>
                      <li>
                      All answers and answer documents you submit including all essay responses, portfolios, and audio or visual work are owned by the College Board, and these may be used by College Board for any purpose, subject to the Privacy Policies located at <a style={{ color: "blue" } as CSSProperties} href="https://about.collegeboard.org/privacy-center">collegeboard.org/privacy-center</a>, and in these Terms and Conditions.
                      </li>
                    </ol>
                    <br />
                    <p>
                    <b>Section 6. ARBITRATION OF DISPUTES AND CLASS ACTION WAIVER</b>
                    </p>
                    <br />
                    <ol className={'terms-and-conditions-list'} type="a">
                      <li>
                      All disputes between you and College Board and/or any or all of its contractors that relate in any way to registering for, participating in, or taking any Pilot program, including but not limited to Pilot Exam(s), but excluding all claims that a party violated the intellectual property rights of the other party, shall exclusively be resolved by a single arbitrator through binding, individual arbitration administered by the American Arbitration Association (“<b><i>AAA</i></b>”) under the AAA Consumer Arbitration Rules in effect at the time a request for arbitration is filed with the AAA. Copies of the AAA Rules can be located at adr.org. Any dispute as to arbitrability shall be resolved by the arbitrator. 
                      </li>
                      <li>
                      Arbitration will be conducted on a documents-only basis (i.e., there will be no in-person or telephone hearing) unless otherwise agreed by the parties or required by the arbitrator.  Should the parties agree to or the arbitrator requires proceedings, such proceedings should be conducted at a location which is reasonably convenient to both parties with due consideration of their ability to travel and other pertinent circumstances. If the parties are unable to agree on a location, the parties agree that the proceedings will be conducted via a video or telephonic call or, in the event that face-to-face proceedings are agreed to by the parties or required by the arbitrator, at a location that is reasonably convenient to both parties in accordance with the AAA Consumer Arbitration Rules.
                      </li>
                      <li>
                      The parties agree that the Federal Arbitration Act (“<b><i>FAA</i></b>”) 9 U.S.C. § 1 et seq. governs this provision, and it is the intent of the parties that the FAA shall preempt all State laws to the fullest extent permitted by law. New York law otherwise applies to these Terms and Conditions, without regard to choice-of-law principles. 
                      </li>
                      <li>
                      No arbitration may be maintained as a class or collective action, and the arbitrator shall not have the authority to combine or aggregate the disputes of more than one individual, conduct any class or collective proceeding, make any class or collective award, or make an award to any person or entity not a party to the arbitration, without the express written consent of College Board.    
                      </li>
                      <li>
                      By agreeing to arbitration in accordance with this section, you are waiving your right to have your dispute heard by a judge or jury.  
                      </li>
                      <li>
                      Each party will be responsible for its own fees and expenses and any legal fees incurred in connection with the arbitration. This does not preclude the arbitrator from granting relief in accordance with AAA Consumer Arbitration Rules.
                      </li>
                    </ol>
                    <br />
                    <p>
                    <b>Section 7.  Governing Law, Venue and Waiver of Jury Trial</b>
                    </p>
                    <p>
                    This Agreement shall be governed by the laws of the state of New York without regard to choice or conflict of law principles.  All disputes arising from or related to this Agreement that are not subject to arbitration under Section 6 shall be resolved exclusively in the state and federal courts located in New York County, New York State and each party to this Agreement irrevocably consents to the jurisdiction of such courts. Each Party expressly waives any right to a jury trial in any lawsuit arising from or related to this Agreement.
                    </p>
                    <p>
                    <b>Section 8. LIMITATION OF LIABILITY</b>
                    </p>
                    <p>
                    EXCEPT TO THE EXTENT FINALLY DETERMINED TO BE PROHIBITED BY LAW, COLLEGE BOARD AND ITS AGENTS AND CONTRACTORS’ TOTAL LIABILITY TO YOU OR ANYONE CLAIMING BY OR THROUGH YOU OR ON YOUR BEHALF, FOR ANY CLAIMS, LOSSES, COSTS, OR DAMAGES ARISING OUT OF OR RESULTING FROM OR IN ANY WAY RELATED TO COLLEGE BOARD, OR ANY TEST ADMINISTRATION BY COLLEGE BOARD, FROM ANY CAUSE, SHALL NOT EXCEED THE TEST REGISTRATION FEES YOU PAID TO COLLEGE BOARD OR $100.00, WHICHEVER IS GREATER. IN ADDITION, COLLEGE BOARD AND ITS AGENTS AND CONTRACTORS SHALL NOT BE LIABLE IN ANY EVENT FOR ANY CONSEQUENTIAL, INDIRECT, PUNITIVE, EXEMPLARY, OR SPECIAL DAMAGES.
                    </p>
                    <p>
                    <b>Section 9. DISCLAIMER OF WARRANTIES</b>
                    </p>
                    <p>
                    COLLEGE BOARD MAKES NO WARRANTIES REGARDING THE PILOT EXAM, EXAM CONTENT, OR TESTING APPLICATION INCLUDING WITHOUT LIMITATION A WARRANTY THAT THE TESTING EXPERIENCE WILL BE UNINTERRUPTED OR ERROR FREE. YOU ACCEPT THE PILOT EXAM, EXAM CONTENT, AND TESTING APPLICATION AS IS.
                    </p>
                    <p>
                    <b>Section 10. Severability</b>
                    </p>
                    <p>
                    If any section or part of this Agreement is held to be invalid, illegal, or unenforceable, the remaining sections or parts will nevertheless continue in full force and effect without being impaired or invalidated in any way and to the extent possible, the invalid, illegal, or unenforceable provision shall be modified so that it is valid, legal, and enforceable and, to the fullest extent, reflects the intention of the parties.
                    </p>
                    <p>
                    <b>Section 11. Restricted Registrations</b>
                    </p>
                    
                    <p>
                    College Board, along with our service providers overseas, is subject to U.S. economic sanctions, laws, and regulations and is prohibited from providing testing services to, or accepting registrations from, persons residing in certain areas or designated by the U.S. government as Specially Designated Nationals and Blocked Persons (collectively, “<b><i>Sanctioned Persons</i></b>”), unless specifically licensed or otherwise authorized by the U.S. government. If a Sanctioned Person attempts to register despite U.S. sanctions that prohibit College Board from doing business with such Sanctioned Person, College Board or a U.S. financial institution may block the registration or payments submitted by or for such Sanctioned Persons. If payment is not blocked, College Board will cancel the registration and may not be able to refund the payment. Please contact Customer Service at 888-225-5427 (+1-212-632-1780 internationally) or visit <a style={{ color: "blue" }} href="https://www.treasury.gov/resource-center/sanctions/programs/pages/programs.aspx">OFAC’s website</a> to obtain the current list of sanctioned programs and Sanctioned Persons.
                    </p>

                    <p>
                    <b>Section 12. Accessibility of These Terms and Conditions</b>
                    </p>
                    
                    <p>
                    If you have difficulty accessing these Terms and Conditions, including our policies and requirements, please contact Customer Service at 888-225-5427 (+1-212-632-1780 internationally) or email <a style={{ color: "blue" }} href="mailto:apstudents@info.collegeboard.org">apstudents@info.collegeboard.org</a> in advance of registering for or taking the Pilot Exam. We will be happy to provide these Terms and Conditions in an alternative format or assist you in some other manner as reasonably necessary to enable you to access these Terms and Conditions.
                    </p>

                    {/* END - Terms and Conditions */}

                  </div>
                </div>
              </div>
            </div>
            {/* End card */}
            <Spacer />
            <div style={{ textAlign: 'center' }}>
              <h4
                style={{
                  color: '#1E1E1E',
                  fontFamily: 'Roboto',
                  fontWeight: 300,
                }}
              >
                Do you accept these terms and conditions?
              </h4>
              <Spacer></Spacer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className="yesButton">
                  <PrimaryButton
                    data-testid="2"
                    disabled={!canAccept}
                    onClick={() => dispatch(updateInviteAction(true))}
                  >
                    Yes
                  </PrimaryButton>
                </div>
                <SpacerH />
                <div className="noButton">
                  <Button data-testid="3" disabled={!canAccept} onClick={() => dispatch(updateInviteAction(false))}>
                    No
                  </Button>
                </div>
              </div>
              <Spacer></Spacer>
            </div>
            <Spacer />
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
