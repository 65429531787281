import React, { FunctionComponent } from 'react';
import { Spacer } from '@cb/apricot-react';

type SpinnerProps = {
  id: string;
  label: string;
  spacing?: '8' | '16' | '24' | '32' | '48' | '72';
};

const Spinner: FunctionComponent<SpinnerProps> = (props: SpinnerProps) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '1.5em',
      marginBottom: '1.5em',
    }}
  >
    {props.spacing ? <Spacer size={props.spacing} /> : null}
    <div role="status" aria-labelledby={props.id}>
      <div className="cb-spinner" id={props.id} role="img" aria-label={props.label} data-cb-apricot="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    {props.spacing ? <Spacer size={props.spacing} /> : null}
  </div>
);

export default Spinner;
