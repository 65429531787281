import { combineReducers } from 'redux';
import { authReducer, AuthState } from './auth.reducer';
import { inviteReducer, InviteState } from './invite.reducer';
import { validateReducer, ValidateState } from './validate.reducer';
import { protocolCheckReducer, ProtocolCheckState } from './protocolCheck.reducer';
import { fsmReducer, FsmState } from './fsm.reducer';

export { AuthIdentity } from './auth.reducer';
export { InviteStatus } from './invite.reducer';
export { ValidateStatus } from './validate.reducer';
export { ProtocolCheckStatus } from './protocolCheck.reducer';
export { UserStatus, SiteStatus } from './fsm.reducer';

export interface State {
  auth: AuthState;
  invite: InviteState;
  validate: ValidateState;
  protocolCheck: ProtocolCheckState;
  fsm: FsmState;
}

export const rootReducers = combineReducers<State>({
  auth: authReducer,
  invite: inviteReducer,
  validate: validateReducer,
  protocolCheck: protocolCheckReducer,
  fsm: fsmReducer,
});
