import { Spacer } from '@cb/apricot-react';
import React from 'react';

type BandProps = {
  title: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
};

const Band: React.FC<BandProps> = (props: BandProps) => {
  return (
    <div className="cb-band cb-band-panel cb-blue2-bg">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="cb-band-panel-content cb-align-center cb-white-color">
              <div className="cb-band-panel-header">
                <div className="row">
                  <div className="col-xs-12 col-md-8 offset-md-2 col-2xl-6 offset-2xl-3">
                    <h2 className="cb-band-panel-title">{props.title}</h2>
                    {props.description ? (
                      <>
                        <Spacer />
                        <h3 className="cb-band-hero-desc" style={{ fontWeight: 300 }}>
                          {props.description}
                        </h3>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Band;
