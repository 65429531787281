// Action types
export const actionIds = {
  LOGIN: 'LOGIN',
  LOGIN_STARTED: 'LOGIN_STARTED',
  LOGIN_SUCCEEDED: 'LOGIN_SUCCEEDED',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_REFRESH: 'LOGIN_REFRESH',
  LOGOUT_STARTED: 'LOGOUT_STARTED',
  LOGOUT_SUCCEEDED: 'LOGOUT_SUCCEEDED',
  SET_EVENTS: 'SET_EVENTS',
  REFRESH_INVITE: 'REFRESH_INVITE',
  REFRESH_INVITE_STARTED: 'REFRESH_INVITE_STARTED',
  REFRESH_INVITE_SUCCESS: 'REFRESH_INVITE_SUCCESS',
  REFRESH_INVITE_ERROR: 'REFRESH_INVITE_ERROR',
  UPDATE_INVITE: 'UPDATE_INVITE',
  UPDATE_INVITE_STARTED: 'UPDATE_INVITE_STARTED',
  UPDATE_INVITE_SUCCESS: 'UPDATE_INVITE_SUCCESS',
  UPDATE_INVITE_ERROR: 'UPDATE_INVITE_ERROR',
  // DOWNLOAD_PLAYER: 'DOWNLOAD_PLAYER',
  // DOWNLOAD_PLAYER_STARTED: 'DOWNLOAD_PLAYER_STARTED',
  // DOWNLOAD_PLAYER_SUCCESS: 'DOWNLOAD_PLAYER_SUCCESS',
  // DOWNLOAD_PLAYER_ERROR: 'DOWNLOAD_PLAYER_ERROR',
  VALIDATE: 'VALIDATE',
  VALIDATE_STARTED: 'VALIDATE_STARTED',
  VALIDATE_SUCCEEDED: 'VALIDATE_SUCCEEDED',
  VALIDATE_FAILED: 'VALIDATE_FAILED',
  PROTOCOL_CHECK: 'PROTOCOL_CHECK',
  PROTOCOL_CHECK_STARTED: 'PROTOCOL_CHECK_STARTED',
  PROTOCOL_CHECK_SUCCEEDED: 'PROTOCOL_CHECK_SUCCEEDED',
  PROTOCOL_CHECK_FAILED: 'PROTOCOL_CHECK_FAILED',
};

// Actions for commands usually follow the pattern
//   COMMAND: 'COMMAND',
//   COMMAND_LOAD_STARTED: 'COMMAND_LOAD_STARTED',
//   COMMAND_LOAD_SUCCESS: 'COMMAND_LOAD_SUCCESS',
//   COMMAND_LOAD_ERROR: 'COMMAND_LOAD_ERROR',

export interface BaseAction {
  type: string;
  //eslint-disable-next-line
  payload?: any;
}

// Action Dependent Types
//   Add types which are arguments for the actions created
export type Raott = string;

// Action Creators
export function loginStartedAction(): BaseAction {
  return { type: actionIds.LOGIN_STARTED };
}
export function loginSucceededAction(data: any): BaseAction {
  return { type: actionIds.LOGIN_SUCCEEDED, payload: data };
}
export function loginFailedAction(error: string): BaseAction {
  return { type: actionIds.LOGIN_FAILED, payload: error };
}
export function loginRefreshAction(): BaseAction {
  return { type: actionIds.LOGIN_REFRESH };
}
export function logoutStartedAction(): BaseAction {
  return { type: actionIds.LOGOUT_STARTED };
}
export function logoutSucceededAction(): BaseAction {
  return { type: actionIds.LOGOUT_SUCCEEDED };
}
export function setEventsAction(dispatch: React.Dispatch<BaseAction>): BaseAction {
  return { type: actionIds.SET_EVENTS, payload: dispatch };
}

export function refreshInviteAction(): BaseAction {
  return { type: actionIds.REFRESH_INVITE };
}

export function refreshInviteStartedAction(): BaseAction {
  return { type: actionIds.REFRESH_INVITE_STARTED };
}

export function refreshInviteSuccessAction(data: any): BaseAction {
  return { type: actionIds.REFRESH_INVITE_SUCCESS, payload: data };
}

export function refreshInviteErrorAction(error: string): BaseAction {
  return { type: actionIds.REFRESH_INVITE_ERROR, payload: error };
}

export function updateInviteAction(accepted: boolean): BaseAction {
  return { type: actionIds.UPDATE_INVITE, payload: accepted };
}

export function updateInviteStartedAction(): BaseAction {
  return { type: actionIds.UPDATE_INVITE_STARTED };
}

export function updateInviteSuccessAction(accepted: boolean): BaseAction {
  return { type: actionIds.UPDATE_INVITE_SUCCESS, payload: accepted };
}

export function updateInviteErrorAction(error: string): BaseAction {
  return { type: actionIds.UPDATE_INVITE_ERROR, payload: error };
}

// export function downloadPlayerAction(os: string): BaseAction {
//   return { type: actionIds.DOWNLOAD_PLAYER, payload: os };
// }

// export function downloadPlayerStartedAction(): BaseAction {
//   return { type: actionIds.DOWNLOAD_PLAYER_STARTED };
// }

// export function downloadPlayerSuccessAction(data: any): BaseAction {
//   return { type: actionIds.DOWNLOAD_PLAYER_SUCCESS, payload: data };
// }

// export function downloadPlayerErrorAction(error: string): BaseAction {
//   return { type: actionIds.DOWNLOAD_PLAYER_ERROR, payload: error };
// }

export function validateAction(raott: Raott): BaseAction {
  return { type: actionIds.VALIDATE, payload: raott };
}
export function validateStartedAction(): BaseAction {
  return { type: actionIds.VALIDATE_STARTED };
}
export function validateSucceededAction(data: any): BaseAction {
  return { type: actionIds.VALIDATE_SUCCEEDED, payload: data };
}
export function validateFailedAction(error: string): BaseAction {
  return { type: actionIds.VALIDATE_FAILED, payload: error };
}
export function checkProtocolAction(data: any): BaseAction {
  return { type: actionIds.PROTOCOL_CHECK, payload: data };
}
export function checkProtocolStartedAction(): BaseAction {
  return { type: actionIds.PROTOCOL_CHECK_STARTED };
}
export function checkProtocolSucceededAction(): BaseAction {
  return { type: actionIds.PROTOCOL_CHECK_SUCCEEDED };
}
export function checkProtocolFailedAction(error: string): BaseAction {
  return { type: actionIds.PROTOCOL_CHECK_FAILED, payload: error };
}

// Create action functions for each actionId created above
// example
/*
export function commandAction(): BaseAction {
  return {type: actionIds.COMMAND };
}

export function commandStartedAction(): BaseAction {
  return {type: actionIds.COMMAND_STARTED };
}

export function commandSuccessAction(): BaseAction {
  return {type: actionIds.COMMAND_SUCESS };
}

export function commandErrorAction(error: string): BaseAction {
  return {type: actionIds.COMMAND_ERROR, payload: error };
}
  */
