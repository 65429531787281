import React from 'react';

type NumberTextProps = {
  number: string | number;
  content: any;
  style?: never;
};

const NumberText: React.FC<NumberTextProps> = (props: NumberTextProps) => {
  return (
    <div className="cb-text-icon-block display-flex cb-align-left" style={{ marginBottom: '20px' }}>
      <div className="flex-shrink-0 cb-margin-right-24 cb-margin-md-down-right-16">
        <span className="cb-glyph-numeric cb-glyph-64 cb-glyph-md-down-32">{props.number}</span>
      </div>
      <div className="cb-text-icon-content" style={{display: 'flex', alignItems: 'center'  }}>
        <p> {props.content} </p>
      </div>
    </div>
  );
};

export default NumberText;
