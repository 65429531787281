import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Layout from '../hoc/Layout';
import Routes from '../components/Routes';
import { store } from '../store';
import {
  loginSucceededAction,
  logoutSucceededAction,
  logoutStartedAction,
  loginStartedAction,
  loginRefreshAction,
  loginFailedAction,
  refreshInviteAction,
} from '../actions';

const { dispatch } = store;
const { iam } = window.cb.core;
const { events } = iam;

const iamBus = iam.getEventBus();
iamBus.on(events.AWSLogin, () => dispatch(loginSucceededAction({})));
iamBus.on(events.AWSLogin, () => dispatch(refreshInviteAction()));
iamBus.on(events.Logout, () => dispatch(logoutSucceededAction()));
iamBus.on(events.AWSLogoutStarted, () => dispatch(logoutStartedAction()));
iamBus.on(events.AWSLoginStarted, () => dispatch(loginStartedAction()));
iamBus.on(events.AWSLoginRefresh, () => dispatch(loginRefreshAction()));
iamBus.on(events.AWSLoginFailed, () => dispatch(loginFailedAction('boom')));

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Layout data-testid="layout">
          <Routes data-testid="routes" />
        </Layout>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
