/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spacer, SpacerH, Button, PrimaryButton } from '@cb/apricot-react';
import BandHero from './BandHero';
import NumberText from './NumberText';

import { updateInviteAction } from '../actions';
import { store } from '../store';
import { State } from '../reducers';
import Modal from './Modal';
const { dispatch } = store;

const Welcome: React.FC = () => {
  const history = useHistory();
  const hasAccepted = useSelector((state: State) => state.invite.item?.data?.accepted);
  const downloadAvailable = useSelector((state: State) => state.invite.item?.downloadPeriod?.isOpen); //.log(state) !== undefined || state);

  const [cancel, showCancel] = useState(false);

  let content = null;
  if (hasAccepted === true && downloadAvailable) {
    content = (
      <>
        {/* <p>
          Thanks for signing up for the pilot. Your digital testing app is{' '}
          <Link to="/download">ready for download</Link>.
        </p> */}

        <p>
          If you decide not to participate, you can still{' '}
          <a href="#cancel" aria-haspopup="dialog" onClick={() => showCancel(true)}>
            cancel your registration
          </a>
          .
        </p>

        <p>
          Otherwise, we’ll send you an email a few days before the test with instructions for you to download the
          digital testing app.
        </p>

        <p>Good luck on test day!</p>
      </>
    );
  } else if (hasAccepted === true) {
    content = (
      <>
        <p>
          Thanks for signing up for the pilot. Your registration is confirmed. If you decide not to participate, you can {' '}
          <a href="#cancel" aria-haspopup="dialog" onClick={() => showCancel(true)}>
            cancel your registration
          </a>
          .
        </p>

        <p>
          Otherwise, we’ll send you an email a few days before the test with instructions for you to download the digital testing app.
        </p>
        <p>Good luck on test day!</p>
      </>
    );
  } else {
    content = (
      <>
        <p>
          We’re running a pilot of our new digital testing application for students taking exams online. 
        </p>
        <p>
          Students in this pilot will take a shortened sample of a digital AP Exam, which will include multiple-choice and free-response questions.
        </p>
        <p>
          The test won’t be scored, but we want you to try your best. Your feedback will help us improve the digital testing experience for all students.
        </p>

        <p>
          Click through this site to confirm your enrollment.
        </p>
      </>
    );
  }

  return (
    <>
      <Modal
        id={1}
        hidden={!cancel}
        title="Are You Sure You Want to Cancel?"
        content="If you cancel your registration now, you will not be able to rejoin the pilot."
        onClose={() => showCancel(false)}
        buttons={[
          {
            label: 'Cancel My Registration',
            className: 'cb-btn-primary cb-confirm-cancel',
            disabled: false,
            onClick: () => dispatch(updateInviteAction(false)),
          },
          {
            label: 'Stay Registered',
            className: 'cb-btn-naked cb-stay-registered',
            disabled: false,
            onClick: () => showCancel(false),
          },
        ]}
      />
      <BandHero title="Welcome to Our Digital Testing Pilot" />
      <div className="container fluid">
        <div className="row ">
          <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
            <Spacer />
            {content}
            <Spacer />
          </div>
        </div>
      </div>
      <div className="band cb-gray5-bg">
        <div className="container fluid">
          <div className="row ">
            <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
              <Spacer />
              <h3
                style={{
                  height: '55px',
                  color: '#1E1E1E',
                  fontFamily: 'Roboto',
                  fontWeight: 300,
                  letterSpacing: '0',
                  lineHeight: '40px',
                }}
              >
                To be in the pilot:
              </h3>
              <NumberText
                number="1"
                content="Pilot exams will take place in April and May 2021. Please see your email for the exact date and time of your pilot exam."
              />
              <NumberText
                number="2"
                content={
                  <div>
                    <p>
                      <b>PC and Mac users:</b> If you haven’t already done so, you’ll go to <a style={{ color: "blue" } as React.CSSProperties} href="https://cb.org/ap2021examapp">cb.org/ap2021examapp</a> to download the app. Your device must be running Windows 8 or later, or Mac OS 10.11 or later.
                    </p> 
                    <p>
                    If you’re using a school-managed device to participate in the pilot, your school will need to install the app on your device.   
                    </p>
                    <p>
                      <b>Chromebook users:</b> Your school will need to install the app on your device, which must be running Chrome OS 83 or later.
                    </p>
                  </div>
                }
              />
              <NumberText
                number="3"
                content="3 days before the pilot exam, you’ll open the app and complete a quick exam setup."
              />
              <NumberText
                number="4"
                content="30 minutes before the pilot exam starts, you’ll open the app and check in."
              />
              <NumberText
                number="5"
                content="After the pilot exam, you’ll complete a short survey about your testing experience."
              />
              <Spacer />
            </div>
          </div>
        </div>
      </div>
      {!hasAccepted ? (
        <>
          <Spacer />
          <div style={{ textAlign: 'center' }}>
            <h4
              style={{
                color: '#1E1E1E',
                fontFamily: 'Roboto',
                fontWeight: 300,
              }}
            >
              Still interested?
            </h4>
            <Spacer />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="yesButton">
                <PrimaryButton onClick={() => history.push('/termsandconditions')}>Yes</PrimaryButton>
              </div>
              <SpacerH />
              <div className="noButton">
                <Button onClick={() => dispatch(updateInviteAction(false))}>No</Button>
              </div>
            </div>
            <Spacer />
          </div>
          <Spacer />
        </>
      ) : null}
    </>
  );
};

export default Welcome;
