import { put, takeLatest } from 'redux-saga/effects';
import {
  actionIds,
  checkProtocolStartedAction,
  checkProtocolFailedAction,
  checkProtocolSucceededAction,
} from '../actions';
import { protocolCheck } from '../api/protocolCheck.api';
import platform from '../util/platform-detect';

function* protocolCheckWithRaott({ payload }: any) {
  try {
    yield put(checkProtocolStartedAction());
    if (platform.id == 'chrome' || platform.id === null) {
      window.location = `https://digital-testing.collegeboard.org/?r=${payload.raott}` as any;
    } else {
      yield protocolCheck(payload.protocol, payload.raott);
    }
    yield put(checkProtocolSucceededAction());
  } catch (e) {
    yield put(checkProtocolFailedAction(e));
  }
}
export function* watchProtocolCheck() {
  yield takeLatest(actionIds.VALIDATE_SUCCEEDED, protocolCheckWithRaott);
}
