import React from 'react';
import Band from './Band';
import Spinner from './Spinner';

const LoadingPage: React.FC = () => (
  <>
    <Band title="Loading" description="Please wait while we look up your information..." />
    <Spinner id="spinner1" label="Please wait while we look up your information..." spacing="16" />
  </>
);

export default LoadingPage;
