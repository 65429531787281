/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';

const defaultHeaders = () => {
  return {
    'X-CB-Catapult-Authorization-Token': window.cb.core.iam.getAuthorizationToken(),
    'X-CB-Catapult-Authentication-Token': window.cb.core.iam.getAuthenticationToken(),
  };
};
//eslint-disable-next-line
export const init = (): Promise<AxiosResponse<any>> => {
  const headers = defaultHeaders();
  const response = axios.get(`/api/init`, {
    headers,
  });
  return response;
};

export const update = (accepted: boolean): Promise<AxiosResponse<any>> => {
  const headers = defaultHeaders();
  const response = axios.post(
    `/api/update`,
    { accepted },
    {
      headers,
    },
  );
  return response;
};

export const download = (os: string): Promise<AxiosResponse<any>> => {
  const headers = defaultHeaders();
  const response = axios.get(`/api/download?OS=${os}`, {
    headers,
  });
  return response;
};

export const validate = (raott: string): Promise<AxiosResponse<any>> => {
  const headers = defaultHeaders();
  const response = axios.get(`/api/validate/${raott}`, {
    headers,
  });
  return response;
};
