const navigator = window.navigator || { userAgent: '' };
const platform = {
  name: 'Unknown',
  simple: 'Unknown',
  id: null,
  others: ['Mac', 'Windows', 'ChromeBook'],
  unknown: true,
};

if (navigator.userAgent.indexOf('Windows') !== -1) {
  platform.name = 'Windows';
  platform.simple = 'Windows';
  platform.id = 'windows';
  platform.others = ['Mac', 'ChromeBook'];
  platform.unknown = false;
}

if (navigator.userAgent.indexOf('Mac') !== -1) {
  platform.name = 'Mac OS X';
  platform.simple = 'Mac';
  platform.id = 'osx';
  platform.others = ['Windows', 'ChromeBook'];
  platform.unknown = false;
}

if (navigator.userAgent.indexOf('CrOS') !== -1) {
  platform.name = 'ChromeBook';
  platform.simple = 'Chrome';
  platform.id = 'chrome';
  platform.others = ['Windows', 'Mac'];
  platform.unknown = false;
}

export default platform;
