export enum AuthIdentity {
  Anonymous,
  MiscUser,
  MiscTarget,
  AuthorizedTarget,
  Raott,
}

export type ParsedJwt = {
  cb?: {
    aarp: string;
  };
};
