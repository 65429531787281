import React from 'react';

type CustomButtonProps = {
  className?: string;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  label: string;
  icon?: string;
};

type BandHeroProps = {
  overline?: string;
  title: string;
  button?: CustomButtonProps;
  description?: string;
};

const BandHero: React.FC<BandHeroProps> = (props: BandHeroProps) => {
  return (
    <div className="cb-band cb-band-hero cb-blue2-bg">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-md-7 col-3xl-5 ">
            <img
              className="cb-img-fluid cb-img-circular cb-img-stretch cb-active-effect cb-fade-in"
              style={{ height: '100%' }}
              alt=""
              src="/hero.png"
            />
          </div>
          <div className="col-sm-4 col-md-5 col-3xl-7">
            <div className="cb-band-hero-content">
              <p className="cb-text-overline">{props.overline}</p>
              <h1 className="cb-band-hero-title" style={{ color: '#fff' }}>
                {props.title}
              </h1>
              {props.button ? (
                <button
                  className={['cb-btn cb-btn-black cb-btn-primary', props.button.className || ''].join(' ')}
                  style={{ marginTop: '16px', color: 'black', background: 'white', border: 'black' }}
                  onClick={props.button.onClick}
                >
                  {props.button.label}
                  {props.button.icon ? (
                    <span className={['cb-glyph', props.button.icon].join(' ')} aria-hidden="true"></span>
                  ) : null}
                </button>
              ) : null}
              <p className="cb-band-hero-desc">{props.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BandHero;
